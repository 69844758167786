// src/components/HallOfFame/HallOfFame.js
import React, { useEffect, useState } from 'react';
import { getUsers, getStartedNovels, getParticipatedNovels } from "../../../firebase/firestore/userService"; // userService의 함수 사용
import './HallOfFame.css';
import {Link} from "react-router-dom";


function HallOfFame() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchHallOfFameData = async () => {
        try {
            const usersData = await getUsers();

            const usersWithStats = await Promise.all(usersData.map(async (user) => {
                const participatedNovels = await getParticipatedNovels(user.id);
                const startedNovels = await getStartedNovels(user.id);

                return {
                    id: user.id,
                    name: user.name,
                    profileImage: user.profileImage || 'https://www.pngarts.com/files/10/Default-Profile-Picture-PNG-Download-Image.png', // 핑크 테마 기본 이미지
                    novelsParticipated: participatedNovels.length,
                    novelsStarted: startedNovels.length,
                };
            }));

            const sortedUsers = usersWithStats.sort((a, b) => {
                if (b.novelsParticipated === a.novelsParticipated) {
                    return b.novelsStarted - a.novelsStarted;
                }
                return b.novelsParticipated - a.novelsParticipated;
            });

            const rankedUsers = sortedUsers.map((user, index) => ({
                ...user,
                rank: index + 1,
            }));

            setUsers(rankedUsers.slice(0, 3)); // 상위 3명
        } catch (error) {
            console.error("Error fetching Hall of Fame data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHallOfFameData();
    }, []);

    if (loading) {
        return <p>명예의 전당 데이터를 불러오는 중입니다...</p>;
    }

    const getMedalEmoji = (rank) => {
        switch (rank) {
            case 1:
                return '🥇'; // 금메달
            case 2:
                return '🥈'; // 은메달
            case 3:
                return '🥉'; // 동메달
            default:
                return '';
        }
    };

    return (
        <section className="hall-of-fame-container">
            <div>
            <h1>👑명예의 전당👑</h1>
            <p className="subtitle">가장 활발하게 활동한 사용자들을 만나보세요!</p>

            <div className="users-grid">
                {users.map((user) => (
                    <div key={user.id} className="user-card">
                        <Link
                            to={`/authors/${user.id}`}
                            style={{
                                textDecoration: 'none', // 밑줄 제거
                                color: 'inherit', // 부모 색상 상속
                            }}
                            className="user-card-link">

                        <img
                            src={user.profileImage}
                            alt={user.name || '익명 사용자'}
                            className="user-image"
                        />
                        <h3>{user.name || '익명 사용자'}</h3>
                        <p>{getMedalEmoji(user.rank)} </p>
                        <p>참여 작품 수: {user.novelsParticipated}</p>
                        <p>시작한 작품 수: {user.novelsStarted}</p>
                        </Link>
                    </div>
                ))}
            </div>
            </div>
        </section>
    );
}

export default HallOfFame;