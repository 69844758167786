//genrePrompts.js
const genrePrompts = {
    로맨스: [
        "비 오는 날, 그와 처음 만난 카페에서 시작된 우리의 이야기.",
        "그가 내 손을 잡은 순간, 모든 것이 달라졌다.",
        "이 작은 도시에서 나만 알고 싶은 비밀 장소가 있다.",
        "그의 눈빛이 내 심장을 흔들었다.",
        "우연히 마주친 골목길에서 사랑이 시작되었다.",
        "꽃집에서 마주친 그 남자, 그는 내가 기다리던 사람이었다.",
        "첫눈에 반한다는 말을 믿지 않았었다. 그날 전까지는.",
        "매일 아침, 그의 미소가 나를 기다리고 있었다.",
        "밤하늘의 별처럼 반짝이던 우리의 첫 키스.",
        "그가 떠난 뒤에도 내 마음속에 남아있는 따뜻한 손길.",
        "오랜 친구였던 우리가 연인이 된 순간.",
        "모든 것이 멈춘 것 같은 순간, 그가 다가왔다.",
        "도서관에서 마주친 그가 내 운명이었다.",
        "우리가 함께한 짧은 여행, 영원히 기억될 사랑.",
        "그의 기타 소리가 내 마음을 흔들었다.",
        "사랑은 먼 곳에 있는 것이 아니라, 바로 옆에 있었다.",
        "그와 함께한 여름밤은 꿈만 같았다.",
        "운명처럼 서로의 이름을 불렀던 그 순간.",
        "우리가 함께한 시간은 짧았지만 강렬했다.",
        "그의 편지 한 장이 내 하루를 밝게 만들었다.",
        "사랑은 내가 생각한 것보다 더 가까이에 있었다.",
        "그가 내 이름을 부를 때마다 세상이 달라 보였다.",
        "첫 만남의 떨림을 아직도 잊을 수 없다.",
        "그와 함께한 길 위의 사랑 이야기.",
        "모든 순간이 우리를 사랑으로 이끌었다."
    ],
    미스터리: [
        "그날 밤, 이상한 발소리가 들려오기 시작했다.",
        "문이 잠겨있었는데, 방 안에 누군가 있었다.",
        "낡은 책에서 발견한 지도 한 장이 사건의 시작이었다.",
        "골목길에서 사라진 소년, 아무도 그의 행방을 모른다.",
        "오래된 저택에서 시작된 불가사의한 사건.",
        "밤마다 나타나는 붉은 빛의 정체는 무엇일까?",
        "책장 뒤에 숨겨진 또 다른 방이 있었다.",
        "모두가 알고 있지만 아무도 말하지 않는 비밀.",
        "검은 장갑을 낀 남자가 모든 것을 알고 있었다.",
        "그가 남긴 암호는 너무 복잡했다.",
        "도시의 어두운 뒷골목에서 펼쳐지는 추적극.",
        "낯선 도시에서 발견된 신비한 일기장.",
        "잃어버린 시간을 찾아가는 여정.",
        "밤마다 울리는 전화벨, 하지만 아무도 없었다.",
        "집 앞에 놓인 정체불명의 소포.",
        "시간이 멈춘 듯한 마을에서 발견된 유일한 증거.",
        "비 오는 밤, 아무도 없는 버스 정류장에서 들려온 목소리.",
        "오랜 친구의 배신이 밝혀지는 순간.",
        "사라진 기억 속에서 찾은 하나의 단서.",
        "그림 속에 숨겨진 살인 사건의 비밀.",
        "낯선 남자가 내 이름을 부르며 다가왔다.",
        "옛 앨범 속 사진이 모든 것을 말해주고 있었다.",
        "어둠 속에서 들려오는 의문의 속삭임.",
        "주인이 없는 낡은 저택에서 들려오는 아이의 웃음소리.",
        "기억 속에서 사라진 단 하나의 이름."
    ],
    코믹: [
        "그는 아침부터 우유 대신 주스를 커피에 넣었다.",
        "길 잃은 로봇 청소기가 집안을 혼란에 빠뜨렸다.",
        "오늘도 기상천외한 실수로 하루를 시작했다.",
        "친구와 함께 만든 케이크는 폭발해버렸다.",
        "이웃집 고양이가 내 집을 점령했다.",
        "최신 기술을 사용한 핸드폰이 나를 곤란하게 만들었다.",
        "내 친구는 하루도 조용할 날이 없었다.",
        "점심시간에 일어난 작은 소동이 대참사가 되었다.",
        "그녀의 반려견은 사실 비밀 요원이었다.",
        "수영장에서 생긴 웃지 못할 해프닝.",
        "오늘도 나의 삶은 시트콤이다.",
        "옷장에서 나온 건 내가 잃어버린 줄 알았던 치킨이었다.",
        "그의 농담은 항상 위험하다.",
        "학교에서 벌어진 창피한 순간들.",
        "기발한 계획이 결국 실패로 끝났다.",
        "웃음으로 가득 찬 하루가 시작되었다.",
        "한밤중에 일어난 유령 소동의 진실.",
        "그는 항상 엉뚱한 길로 나를 인도한다.",
        "웃지 말라는데, 나는 이미 바닥을 구르고 있었다.",
        "일상 속에서 발견한 황당한 사실.",
        "그의 말장난에 모두가 웃음을 터뜨렸다.",
        "아이스크림을 사러 갔다가 엉뚱한 곳에 도착했다.",
        "웃음은 항상 가장 좋은 약이다.",
        "도시락 속에서 발견된 믿을 수 없는 메시지.",
        "그의 기발한 발명품은 항상 재앙이었다."
    ],
    액션: [
        "그는 마침내 적진 한가운데로 뛰어들었다.",
        "폭발 소리와 함께 임무가 시작되었다.",
        "한 손엔 총, 다른 한 손엔 희망을 들었다.",
        "위험천만한 다리 위에서의 대결.",
        "아무도 그의 반격을 예상하지 못했다.",
        "도심 한복판에서 벌어진 대규모 추격전.",
        "그녀의 칼날이 적의 방어를 뚫었다.",
        "최후의 순간, 그는 결코 물러서지 않았다.",
        "헬리콥터에서 뛰어내리는 그 순간.",
        "무너지는 빌딩에서 탈출해야 했다.",
        "그의 손목에는 정체불명의 디바이스가 있었다.",
        "총성이 울리는 가운데에도 침착함을 잃지 않았다.",
        "적진 깊숙이 잠입한 그의 용기.",
        "폭우 속에서의 처절한 몸싸움.",
        "시간이 멈춘 듯한 순간, 모든 것이 결정되었다.",
        "유령처럼 어둠 속에서 나타나는 그.",
        "모두의 운명이 그의 손끝에 달렸다.",
        "사막에서 펼쳐지는 생존의 싸움.",
        "그는 누구도 따라갈 수 없는 속도로 달렸다.",
        "그의 파괴력은 적의 예상을 초월했다.",
        "고속 열차에서의 숨 막히는 전투.",
        "그의 마지막 카드는 모두를 놀라게 했다.",
        "수천 명의 목숨을 구한 그날.",
        "폭발의 중심에서도 그는 미소를 잃지 않았다.",
        "다시는 돌아오지 않을 임무를 맡았다."
    ],
    스릴러: [
        "문 밖에서 들려오는 낮은 속삭임.",
        "그의 전화는 밤마다 끊기지 않았다.",
        "도망치려 했지만, 이미 늦어버렸다.",
        "그는 내가 가진 마지막 희망이었다.",
        "모두가 잠든 사이, 그림자가 움직였다.",
        "불 꺼진 방에서 들려오는 기이한 소리.",
        "아무도 모르게 사라진 그날 밤.",
        "누군가 나를 지켜보고 있었다.",
        "그날 이후, 나의 일상은 사라졌다.",
        "의문의 남자가 내 집 문 앞에 서 있었다.",
        "숨길 수 없는 진실이 점점 다가왔다.",
        "그의 손에 들린 것은 내가 잃어버린 물건이었다.",
        "도시의 어두운 길목에서 들려온 비명 소리.",
        "그의 웃음소리는 나를 두렵게 했다.",
        "기억 속의 사건이 되살아났다.",
        "그녀의 눈동자에는 무언가 숨겨져 있었다.",
        "멈출 수 없는 차가 내 앞에서 서 있었다.",
        "모두가 그를 피해 다녔다.",
        "그날 밤, 내가 본 것은 환영이 아니었다.",
        "그의 마지막 한마디가 모든 것을 바꿨다.",
        "고요 속에서 울리는 신호음.",
        "지하철역에서의 마지막 대화.",
        "그는 아무런 흔적도 남기지 않았다.",
        "그림자가 드리운 창문 너머로 무언가 보였다.",
        "그의 마지막 숨소리가 멈춘 순간."
    ],
    판타지: [
        "하늘을 나는 섬이 내 눈앞에 나타났다.",
        "잃어버린 왕국의 비밀을 풀어야 했다.",
        "그의 손에는 빛나는 수정 구슬이 있었다.",
        "마법의 문을 열면 또 다른 세계가 있었다.",
        "드래곤과 인간의 마지막 전쟁.",
        "잊혀진 숲의 중심에 서 있는 거대한 나무.",
        "그녀는 내가 찾던 전설 속의 인물이었다.",
        "마법사의 마지막 주문이 세상을 바꿨다.",
        "시간을 되돌릴 수 있는 시계가 있었다.",
        "그의 검은 살아있는 듯 움직였다.",
        "마법의 책장이 새로운 모험을 시작하게 했다.",
        "별들 사이를 여행하는 고대의 배.",
        "하늘에서 떨어진 기이한 생명체.",
        "고대의 유물을 찾아 떠나는 여정.",
        "그의 눈빛에서 무언가 특별한 것이 느껴졌다.",
        "모두가 두려워하던 검은 성의 주인.",
        "마법사가 내 손에 하나의 열쇠를 쥐어주었다.",
        "숲 속에서 들려오는 기이한 음악.",
        "잊혀진 도시의 중심에서 발견된 단서.",
        "시간과 공간을 초월한 만남.",
        "그녀의 날개는 밤하늘을 덮었다.",
        "오랜 전설 속의 영웅이 깨어났다.",
        "달빛 아래에서 펼쳐진 마지막 결투.",
        "마법의 힘을 통제할 수 있는 유일한 방법.",
        "잃어버린 세계를 찾아 떠나는 여정."
    ],
    일상: [
        "오늘 아침, 평소와 다름없이 시작되었다.",
        "카페에서 만난 새로운 친구와의 대화.",
        "길에서 잃어버린 지갑을 찾았다.",
        "오늘도 아파트 복도에서 만난 낯선 고양이.",
        "학교에서 들려온 선생님의 재밌는 이야기.",
        "지하철에서 우연히 마주친 옛 친구.",
        "퇴근 후의 따뜻한 커피 한 잔.",
        "운동을 끝내고 마신 시원한 물의 맛.",
        "도서관에서 발견한 오래된 책 한 권.",
        "집 앞에서 만난 친절한 이웃.",
        "아침 햇살이 창문을 통해 들어왔다.",
        "작은 공원에서 만난 귀여운 강아지.",
        "장 보러 갔다가 예상치 못한 세일.",
        "새로 산 화분에 심은 작은 식물.",
        "저녁 산책 중 들려온 아이들의 웃음소리.",
        "책상 위에 놓인 어제의 메모.",
        "빨래를 끝내고 느끼는 뿌듯함.",
        "저녁 식탁에서 가족과 나눈 이야기.",
        "길거리에서 들려온 버스킹의 멜로디.",
        "오늘도 마주친 그 고양이의 눈빛.",
        "출근길에서 우연히 들은 뉴스.",
        "하늘에 떠 있는 구름 모양이 재밌었다.",
        "마을 축제에서 만난 오래된 친구.",
        "길 잃은 어린아이를 도와준 하루.",
        "창밖으로 보이는 아름다운 노을."
    ],
    공포: [
        "어두운 골목 끝에서 누군가 날 부르고 있었다.",
        "거울 속 내 모습이 움직이지 않았다.",
        "갑자기 멈춘 엘리베이터 안의 정체불명의 소리.",
        "밤마다 방문을 두드리는 기이한 소리.",
        "텅 빈 병원 복도에서 들려온 아이의 울음소리.",
        "혼자 있는 방에서 느껴진 낯선 기운.",
        "가장 친한 친구가 내 앞에서 사라졌다.",
        "발자국 소리가 내 뒤를 따라오고 있었다.",
        "폐가에서 발견한 오래된 사진 한 장.",
        "그림 속 인물이 점점 가까워지고 있었다.",
        "라디오에서 들려오는 낯선 목소리.",
        "아무도 없는 방에 켜져 있는 불빛.",
        "숲 속에서 길을 잃은 밤.",
        "낯선 번호로 걸려온 전화를 받지 말았어야 했다.",
        "밤하늘을 가로지르는 이상한 빛.",
        "가까운 사람조차 믿을 수 없게 된 날.",
        "옛날 일기를 읽다가 발견한 기묘한 문장.",
        "서랍에서 발견된 기이한 물건.",
        "어디서 본 적 없는 얼굴이 집에 있었다.",
        "사라진 동생을 찾는 동안의 끔찍한 발견.",
        "누군가 내 침대 밑에 숨어 있었다.",
        "눈을 감으면 들리는 속삭임.",
        "잠에서 깨어보니 내가 아니었다.",
        "어두운 방 안에서 들리는 발소리.",
        "사라진 이웃에 대한 끔찍한 진실."
    ]
};

export default genrePrompts;
